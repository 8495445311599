#partners {
  background-color: #6A8EAE;

  img {
    width: 6rem;
    transition: scale .5s ease;

    &:hover {
      scale: 1.8;
    }

    @include media-breakpoint-up(lg) {
      width: 12rem;
    }
  }
}
