#page-header {
  background-color: #54F98D;

  @include media-breakpoint-up(md) {
    min-height: 90vh;

    img[alt="logo"] {
      height: 80vh;
      width: 75%;
      object-fit: contain;
    }
  }

  a.social-network {
    color: $black;

    &:hover {
      color: $gray-600;
    }
  }
}
